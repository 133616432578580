import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DownloadIcon from "@mui/icons-material/Download";
import { isImageUrl, isPdfUrl, scrollToRepliedMsg } from "helpers/func";

const leftMsgContainer = "flex";
const rightMsgContainer = "flex justify-end";
const leftMsg =
  "bg-[#3E5EA9] rounded-tr-2xl rounded-bl-2xl rounded-br-2xl p-[0.8rem]";
const rightMsg =
  "bg-[#F5F5F5] rounded-tl-2xl rounded-bl-2xl rounded-br-2xl p-[0.8rem]";

const isAlignedLeft = (align) => align === "left";

const Container = ({ children, align }) => {
  const classes = isAlignedLeft(align) ? leftMsgContainer : rightMsgContainer;
  return <div className={classes}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(["right", "left"]).isRequired,
};

const MsgContainer = ({ children, align }) => {
  const mainClasses = "max-w-[70%] z-10";
  const extraClasses = isAlignedLeft(align) ? leftMsg : rightMsg;
  const classes = mainClasses + " " + extraClasses;

  return <div className={classes}>{children}</div>;
};

MsgContainer.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(["right", "left"]).isRequired,
};

const TimeStamp = ({ timeStamp, align }) => {
  const mainClasses = "text-[10px]";
  const extraClasses = isAlignedLeft(align) ? "text-white" : "text-black";
  const classes = mainClasses + " " + extraClasses;
  return <div className={classes}>{timeStamp}</div>;
};

TimeStamp.propTypes = {
  timeStamp: PropTypes.string.isRequired,
  align: PropTypes.oneOf(["right", "left"]).isRequired,
};

const MsgTimeReadContainer = ({ children }) => {
  return (
    <div className={"flex justify-end items-center pl-10"}>{children}</div>
  );
};

MsgTimeReadContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const DeliveryStatus = ({ status }) => {
  return (
    <div className="flex justify-center items-center ml-2">
      {status === "sent" && (
        <DoneIcon sx={{ fontSize: "1.3rem", color: "#3e5ea9" }} />
      )}
      {status === "delivered" && (
        <DoneAllIcon sx={{ fontSize: "1.3rem", color: "#3e5ea9" }} />
      )}
    </div>
  );
};

DeliveryStatus.propTypes = {
  status: PropTypes.oneOf(["sent", "delivered"]).isRequired,
};

const MsgType = (content, isAlignedLeft) => {
  const buttonClasses = `hover:underline font-light ${
    isAlignedLeft ? "text-white" : "text-black"
  }`;
  const textClasses = `text-[14px] font-[500] break-words ${
    isAlignedLeft === undefined && "text-[#757886]"
  } ${isAlignedLeft === true && "text-white"} ${
    isAlignedLeft === false && "text-black"
  }`;
  return {
    image: (
      <img
        src={content}
        className="w-full h-[200px]"
        style={{ marginBottom: "5px" }}
        alt=""
      />
    ),
    pdf: (
      <a
        style={{ textDecoration: "none", marginBottom: "5px" }}
        href={`${content}`}
        rel="noreferrer"
        download=""
      >
        <button className={buttonClasses}>
          Document <DownloadIcon />
        </button>
      </a>
    ),
    text: <p className={textClasses}>{content}</p>,
  };
};

const MsgContent = ({ content, isAlignedLeft }) => {
  const type = () => {
    if (isImageUrl(content || "")) {
      return "image";
    } else if (isPdfUrl(content)) {
      return "pdf";
    } else {
      return "text";
    }
  };
  return MsgType(content, isAlignedLeft)[type()];
};

export const Message = ({
  align,
  message,
  status,
  showStatus,
  consultationInfo,
}) => {
  const { patientData, doctorData } = consultationInfo;
  const { timestamp, _id, content } = message;
  const isAlignedLeft = align === "left";
  const stamp = `${format(timestamp.toDate(), "p")}`; // toDate(); //toDateString
  const repliedTo = message?.replyTo;
  const mainDivClasses = `relative transition-all ${
    isAlignedLeft ? "msg-left" : "msg-right"
  }`;
  const messageContClasses = `border-l-[3px] pl-4 py-2 pr-2 my-2 rounded-md mb-4 ${
    isAlignedLeft ? "border-blue-300 bg-gray-100" : "border-primary bg-gray-200"
  }`;

  return (
    <div id={`msg_${_id}`} className={mainDivClasses}>
      <Container align={align}>
        <MsgContainer align={align}>
          {repliedTo && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div
              onClick={(e) => {
                e.preventDefault();
                scrollToRepliedMsg(
                  repliedTo._id,
                  repliedTo.idFrom !== patientData._id
                );
              }}
              className={messageContClasses}
            >
              <p className="text-primary text-[12px] font-medium">
                {repliedTo?.idFrom === patientData?._id
                  ? "You"
                  : `Dr. ${doctorData.firstName}`}
              </p>
              <p className="text-[14px] font-light text-black break-words">
                {MsgContent({ content: repliedTo?.content })}
              </p>
            </div>
          )}
          <div>
            {MsgContent({ content, isAlignedLeft })}
            <MsgTimeReadContainer>
              <TimeStamp timeStamp={stamp} align={align} />
              {showStatus && <DeliveryStatus status={status} />}
            </MsgTimeReadContainer>
          </div>
        </MsgContainer>
      </Container>
    </div>
  );
};

Message.propTypes = {
  align: PropTypes.oneOf(["right", "left"]).isRequired,
  message: PropTypes.object.isRequired,
  status: PropTypes.oneOf(["sent", "delivered"]),
  showStatus: PropTypes.bool,
  consultationInfo: PropTypes.object.isRequired,
};
