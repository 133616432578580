import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import useAlert from "hooks/useAlert";
import { NoData } from "components/layouts";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MainModal from "components/modals/MainModal";
import { useLazyQuery, useQuery } from "@apollo/client";
import { DisputeForm } from "components/Forms/DisputeForm";
import { CustomButton, Loader } from "components/Utilities";
import { disputeBtnText, disputeTypeObj } from "helpers/mockData";
import { getConsult, getProviderLogo } from "components/graphQL/useQuery";
import { DoctorInfoCard } from "components/cards/consultationInfoCards/DoctorInfoCard";
import { PatientInfoCard } from "components/cards/consultationInfoCards/PatientInfoCard";
import { BasicConsultationInfoCard } from "components/cards/consultationInfoCards/BasicInfoCard";

const CaseNotes = () => {
  const theme = useTheme();
  const { rowId } = useParams();
  const { getErrorMsg, displayAlert } = useAlert();
  const [disputeType, setDisputeType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [
    fetchProviderLogo,
    { data: providerLogoData, loading: loadingProviderLogo },
  ] = useLazyQuery(getProviderLogo, {
    nextFetchPolicy: "no-cache",
  });

  const providerLogo = providerLogoData?.getProvider?.icon || "";

  const { loading, data, error, refetch } = useQuery(getConsult, {
    variables: {
      id: rowId,
    },
  });

  const buttonType = useMemo(
    () => ({
      background: theme.palette.common.black,
      hover: theme.palette.primary.main,
      active: theme.palette.primary.dark,
      disabled: theme.palette.common.black,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resolveBtn = useMemo(
    () => ({
      background: "#3EA584",
      hover: "#308066",
      active: "#308066",
      disabled: "#7BCEB3",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const DisputeBtn = useMemo(
    () => ({
      background: "#f32828",
      hover: "#dc0c0c",
      active: "#cc0b0b",
      disabled: "#f76868",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const primaryBtn = useMemo(
    () => ({
      background: theme.palette.common.blue,
      hover: "#304984",
      active: "#304984",
      disabled: "#96AAD9",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (data) {
      const providerId = data.getConsultation?.providerId;
      fetchProviderLogo({ variables: { providerId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { status, isDisputed, disputeStatus, isDisputeResolved } =
    data?.getConsultation || {};

  const headerText = useMemo(() => disputeBtnText[disputeType], [disputeType]);

  const isNotDeputed = useMemo(
    () => !isDisputed && disputeStatus === "pending",
    [disputeStatus, isDisputed]
  );

  const showCreateDisputeBtn = useMemo(
    () =>
      !isDisputed &&
      disputeStatus === "pending" &&
      !isDisputeResolved &&
      status === "completed",
    [disputeStatus, isDisputeResolved, isDisputed, status]
  );

  const showResolveDisputeBtns = useMemo(
    () => isDisputed && status === "completed",
    [isDisputed, status]
  );

  const consultationInfo = data?.getConsultation || {};

  if (loading) return <Loader />;
  if (error) return <NoData error={error} />;

  return (
    <>
      <Grid container direction="column" gap={1} sx={{ margin: "3rem 0rem" }}>
        {/* PROVIDER LOGO */}
        <Grid item container justifyContent="center" marginBottom="1rem">
          {loadingProviderLogo ? (
            <Loader />
          ) : (
            <img
              src={providerLogo}
              alt="provider logo"
              style={{ maxWidth: "150px", maxHeight: "80px" }}
            />
          )}
        </Grid>

        {/* ===== CONSULTATION GENERAL INFORMATION ===== */}
        <BasicConsultationInfoCard consultationInfo={consultationInfo} />

        {/* ===== CONSULTATION PATIENT INFORMATION ===== */}
        <PatientInfoCard consultationInfo={consultationInfo} />

        {/* ===== CONSULTATION DOCTOR INFORMATION ===== */}
        <DoctorInfoCard consultationInfo={consultationInfo} />

        {/* BUTTONS  */}
        <Grid container columnGap={2}>
          {showResolveDisputeBtns && (
            <>
              <CustomButton
                title="Mark disputed"
                type={DisputeBtn}
                onClick={() => {
                  setDisputeType(disputeTypeObj.RESOLVE);
                  setIsModalOpen(true);
                }}
              />
              <CustomButton
                title="Resolve dispute"
                type={resolveBtn}
                onClick={() => {
                  setDisputeType(disputeTypeObj.CANCEL);
                  setIsModalOpen(true);
                }}
              />
            </>
          )}
          {showCreateDisputeBtn && (
            <CustomButton
              title="Create dispute"
              type={buttonType}
              onClick={() => {
                setDisputeType(disputeTypeObj.CREATE);
                setIsModalOpen(true);
              }}
            />
          )}
          {status === "ongoing" && (
            <>
              <CustomButton
                title="Cancel consultation"
                type={DisputeBtn}
                onClick={() => {
                  setDisputeType(disputeTypeObj.CANCEL_CONSULT);
                  setIsModalOpen(true);
                }}
              />
              <CustomButton
                title="Complete consultation"
                type={resolveBtn}
                onClick={() => {
                  setDisputeType(disputeTypeObj.COMPLETE);
                  setIsModalOpen(true);
                }}
              />
            </>
          )}
          {/* RESEND EMAIL */}
          {status === "completed" && isNotDeputed && (
            <CustomButton
              title="Resend email"
              type={primaryBtn}
              onClick={() => {
                setDisputeType(disputeTypeObj.RESEND);
                setIsModalOpen(true);
              }}
            />
          )}
        </Grid>
      </Grid>

      {/* DISPUTE FORM */}
      <MainModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        headerText={headerText}
      >
        <DisputeForm
          type={disputeType}
          closeModal={() => setIsModalOpen(false)}
          onSuccess={async () => {
            try {
              setIsModalOpen(false);
              refetch();
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
              const errMsg = getErrorMsg(error);
              displayAlert("error", errMsg);
            }
          }}
        />
      </MainModal>
    </>
  );
};

export default CaseNotes;
