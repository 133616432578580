import React, { useEffect, useMemo, useRef, useState } from "react";
import { isAfter } from "date-fns";
import useAlert from "hooks/useAlert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { isSelected } from "helpers/isSelected";
import MainModal from "components/modals/MainModal";
import { dateMoment } from "components/Utilities/Time";
import { removeEmptyStringValues } from "helpers/func";
import TableLayout from "components/layouts/TableLayout";
import { CustomButton, Loader } from "components/Utilities";
import { consultationFilterDefaultVal } from "helpers/mockData";
import { DatePickerComponent } from "components/validation/Date";
import { Grid, TableRow, TableCell, Typography } from "@mui/material";
import { EnhancedTable, NoData, EmptyTable } from "components/layouts";
import { useConsultationsStyles } from "styles/consultationsPageStyles";
import { allDisputesHeader } from "components/Utilities/tableHeaders";
import {
  exportConsultations,
  getConsultations
} from "components/graphQL/useQuery";
import {
  changeTableLimit,
  handlePageChange
} from "helpers/filterHelperFunctions";
import StatusPill from "components/Utilities/StatusPill";
import DisputeFilters from "components/Forms/Filters/DisputeFilters";

const Disputes = () => {
  const theme = useTheme();
  const history = useHistory();
  const downloadBtn = useRef();
  const { getErrorMsg } = useAlert();
  const classes = useConsultationsStyles();
  const [errMsg, setErrMsg] = useState(null);
  const [pageInfo, setPageInfo] = useState({});
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [exportModal, setExportModal] = useState(false);
  const [disputedConsultations, setDisputedConsultations] = useState([]);
  const { selectedRows } = useSelector((state) => state.table);
  const [loadingExportUrl, setLoadingExportUrl] = useState(false);
  const [generateDownloadUrl] = useLazyQuery(exportConsultations);
  const [startEndDate, setStartEndDate] = useState({
    startDate: null,
    endDate: null
  });
  const [disputesFilterState, setDisputesFilterState] = useState({
    ...consultationFilterDefaultVal,
    disputeStatus: "pending"
  });

  const [
    fetchDisputedConsultations,
    { loading, data, error, variables, refetch }
  ] = useLazyQuery(getConsultations);

  const queryParams = useMemo(
    () => ({
      additionalVar: {
        orderBy: "-createdAt",
        first: pageInfo?.limit || 10,
        wasDisputed: true
      },
      varToDelete: variables,
      fetchDisputedConsultations,
      refetch
    }),
    [fetchDisputedConsultations, pageInfo?.limit, refetch, variables]
  );

  const buttonType = useMemo(
    () => ({
      background: theme.palette.common.black,
      hover: theme.palette.primary.main,
      active: theme.palette.primary.dark
    }),
    [
      theme.palette.common.black,
      theme.palette.primary.dark,
      theme.palette.primary.main
    ]
  );

  useEffect(() => {
    fetchDisputedConsultations({
      variables: {
        orderBy: "-createdAt",
        wasDisputed: true,
        disputeStatus: "pending"
      }
    });
  }, [fetchDisputedConsultations]);

  useEffect(() => {
    if (data) {
      setDisputedConsultations(data.getConsultations.data);
      setPageInfo(data.getConsultations.pageInfo);
    }
  }, [data, disputedConsultations]);

  if (error) return <NoData error={error.message} />;

  return (
    <Grid container gap={2} flexWrap="nowrap" direction="column" height="100%">
      <Grid
        item
        container
        justifyContent="flex-end"
        sx={{ margin: "1rem 0rem" }}
      >
        <div>
          <CustomButton
            title="Export data"
            width="100%"
            type={buttonType}
            onClick={() => {
              setExportModal(true);
            }}
          />
        </div>
      </Grid>
      <TableLayout
        filters={
          <DisputeFilters
            filterState={disputesFilterState}
            setFilterState={setDisputesFilterState}
            queryParams={queryParams}
            disabled={loading}
          />
        }
      >
        {loading ? (
          <Loader />
        ) : disputedConsultations.length > 0 ? (
          <Grid item container direction="column" height="100%">
            <EnhancedTable
              headCells={allDisputesHeader}
              rows={disputedConsultations}
              paginationLabel="Disputed Consultations per page"
              hasCheckbox={false}
              sx={{ cursor: "pointer" }}
              changeLimit={async (e) => {
                const filterVar = removeEmptyStringValues(variables);
                await changeTableLimit(fetchDisputedConsultations, {
                  ...filterVar,
                  first: e,
                  orderBy: "-createdAt"
                });
              }}
              dataPageInfo={pageInfo}
              handlePagination={async (page) => {
                const filterVar = removeEmptyStringValues(variables);
                await handlePageChange(
                  fetchDisputedConsultations,
                  page,
                  pageInfo,
                  {
                    orderBy: "-createdAt",
                    ...filterVar
                  }
                );
              }}
            >
              {disputedConsultations.map((row) => {
                const {
                  doctorData,
                  patientData,
                  _id,
                  createdAt,
                  symptoms,
                  type,
                  status,
                  contactMedium
                } = row;

                const isItemSelected = isSelected(_id, selectedRows);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={_id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/disputes/${_id}`);
                    }}
                  >
                    <TableCell align="left" className={classes.tableCell}>
                      {dateMoment(createdAt)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ maxWidth: "25rem" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <span style={{ fontSize: "1.25rem" }}>
                          {doctorData.firstName
                            ? `${doctorData.firstName} ${doctorData.lastName}`
                            : "No Doctor"}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ maxWidth: "25rem" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <span style={{ fontSize: "1.25rem" }}>
                          {patientData
                            ? `${patientData?.firstName} ${patientData?.lastName}`
                            : "No Patient"}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Grid container gap={1}>
                        {symptoms
                          ? symptoms.map((symptom, index) => {
                              const isLast = index === row.symptoms.length - 1;
                              return (
                                <p key={symptom.name}>
                                  {isLast
                                    ? `${symptom.name}.`
                                    : `${symptom.name},`}
                                </p>
                              );
                            })
                          : "No Value"}
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                        width: "4rem"
                      }}
                    >
                      {contactMedium ? contactMedium : "No Value"}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey
                      }}
                    >
                      {type ? type : "No Value"}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <StatusPill
                        label={status ? status : "No status"}
                        type={
                          status === "cancelled"
                            ? "error"
                            : status === "completed"
                            ? "success"
                            : status === "ongoing"
                            ? "normal"
                            : "unknown"
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </EnhancedTable>
            <MainModal
              isOpen={exportModal}
              setIsOpen={() => {
                if (loadingExportUrl) return;
                setExportModal(false);
              }}
              headerText="Export consultations."
            >
              <Grid
                container
                justifyContent="space-between"
                sx={{ marginTop: "2rem" }}
              >
                <Grid item sx={{ maxWidth: "200px" }}>
                  <Typography>Start date</Typography>
                  <DatePickerComponent
                    onHandleChange={(value) => {
                      setDownloadUrl(null);
                      setErrMsg(null);
                      setStartEndDate((prev) => ({
                        ...prev,
                        startDate: value
                      }));
                    }}
                  />
                </Grid>
                <Grid item sx={{ maxWidth: "200px" }}>
                  <Typography>End date</Typography>
                  <DatePickerComponent
                    onHandleChange={(value) => {
                      setDownloadUrl(null);
                      setErrMsg(null);
                      setStartEndDate((prev) => ({
                        ...prev,
                        endDate: value
                      }));
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item alignSelf="flex-end">
                <Grid
                  item
                  sx={{
                    paddingBottom: "1rem"
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FF0400",
                      textAlign: "center",
                      fontSize: "0.8em"
                    }}
                  >
                    {errMsg}
                  </Typography>
                </Grid>
                <Grid container gap={2}>
                  <Grid item>
                    {startEndDate.startDate &&
                      startEndDate.endDate &&
                      !downloadUrl && (
                        <CustomButton
                          disabled={loadingExportUrl}
                          isSubmitting={loadingExportUrl}
                          title="Generate file"
                          type={buttonType}
                          textColor="#fff"
                          textColorOnHover="#3E5EA9"
                          onClick={async () => {
                            try {
                              setLoadingExportUrl(true);
                              const { startDate, endDate } = startEndDate;
                              const parseDate = (date) =>
                                new Date(Date.parse(date));
                              const isEndDateAfter = isAfter(
                                parseDate(endDate),
                                parseDate(startDate)
                              );
                              if (!isEndDateAfter) {
                                setErrMsg("End date must be after Start date");
                                setLoadingExportUrl(false);
                                return;
                              }
                              const res = await generateDownloadUrl({
                                variables: {
                                  orderBy: "-createdAt",
                                  startDate: startEndDate.startDate,
                                  endDate: startEndDate.endDate
                                }
                              });
                              const url =
                                res?.data?.exportConsultations?.fileUrl;
                              setDownloadUrl(url);
                              setLoadingExportUrl(false);
                            } catch (error) {
                              setLoadingExportUrl(false);
                              // eslint-disable-next-line no-console
                              console.error(error);
                              const errMsg = getErrorMsg(error);
                              setErrMsg(errMsg);
                            }
                          }}
                        />
                      )}
                    {downloadUrl && (
                      <a
                        href={downloadUrl}
                        ref={downloadBtn}
                        style={{
                          display: "block",
                          marginTop: "0.5rem"
                        }}
                        rel="noreferrer"
                        download={`${startEndDate.startDate}-${startEndDate.endDate}-consultations`}
                      >
                        Click to Download file
                      </a>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </MainModal>
          </Grid>
        ) : (
          <EmptyTable
            headCells={allDisputesHeader}
            paginationLabel="Disputed consultations per page"
          />
        )}
      </TableLayout>
    </Grid>
  );
};

export default Disputes;
