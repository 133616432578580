import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { dateMoment } from "helpers/func";
import { useLazyQuery } from "@apollo/client";
import { Loader } from "components/Utilities";
import { defaultPageInfo } from "helpers/mockData";
import MainModal from "components/modals/MainModal";
import { useStyles } from "styles/patientsPageStyles";
import { useParams, useHistory } from "react-router-dom";
import TableLayout from "components/layouts/TableLayout";
import { EditDelBtn } from "components/Buttons/EditDelBtn";
import { EmptyTable, EnhancedTable, NoData } from "components/layouts";
import { daily, duration } from "components/Utilities/Time";
import { getPrescriptions } from "components/graphQL/useQuery";
import {
  Chip,
  Divider,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  patientPrescriptionHeadCells,
  prescriptionTable,
} from "components/Utilities/tableHeaders";
import {
  changeTableLimit,
  handlePageChange,
} from "helpers/filterHelperFunctions";

const Prescriptions = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { patientId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [singlePrescription, setSinglePrescription] = useState({});
  const [fetchPrescriptions, { loading, error, data, variables }] =
    useLazyQuery(getPrescriptions, {
      variables: {
        patientId,
        orderBy: "-createdAt",
      },
    });

  const prescriptions = data?.getPrescriptions?.data || [];

  useEffect(() => {
    fetchPrescriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageInfo(data?.getPrescriptions?.pageInfo);
  }, [data]);

  return (
    <Grid container direction="column" flexWrap="nowrap" height="100%" gap={2}>
      <TableLayout>
        {error ? (
          <NoData error={error} />
        ) : loading ? (
          <Loader />
        ) : (
          <Grid
            container
            item
            direction="column"
            overflow="hidden"
            maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
          >
            {prescriptions.length > 0 ? (
              <EnhancedTable
                headCells={patientPrescriptionHeadCells}
                rows={prescriptions}
                paginationLabel="Prescription per page"
                hasCheckbox={false}
                changeLimit={async (e) => {
                  changeTableLimit(fetchPrescriptions, {
                    ...variables,
                    first: e,
                  });
                }}
                dataPageInfo={pageInfo}
                handlePagination={async (page) => {
                  handlePageChange(fetchPrescriptions, page, pageInfo, {
                    ...variables,
                  });
                }}
              >
                {prescriptions.map((row, index) => {
                  const { _id, updatedAt, doctorData, drugs } = row;
                  const { firstName, lastName } = doctorData;
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const drugNames =
                    drugs && drugs?.length > 0
                      ? drugs.map((drug) => drug?.drugName)
                      : null;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                        style={{
                          color: theme.palette.common.grey,
                          textAlign: "left",
                          textWrap: "nowrap",
                        }}
                      >
                        {dateMoment(updatedAt)}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <button
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "left",
                            cursor: "pointer",
                            border: "none",
                            backgroundColor: "transparent",
                            borderBottom: "1px solid #000",
                            textWrap: "nowrap",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/hcps/${doctorData?._id}`);
                          }}
                        >
                          <span style={{ fontSize: "1.25rem" }}>{`${
                            firstName && firstName
                          } ${lastName && lastName}`}</span>
                        </button>
                      </TableCell>

                      <TableCell align="left" className={classes.tableCell}>
                        {/* drugs */}
                        {drugNames ? (
                          <Grid container gap={1}>
                            {drugNames.map((name, idx) => {
                              return (
                                <Chip
                                  key={`${name}-${idx}`}
                                  label={name}
                                  className={classes.badge}
                                  style={{
                                    background: theme.palette.common.lightGreen,
                                    color: theme.palette.common.green,
                                  }}
                                />
                              );
                            })}
                          </Grid>
                        ) : (
                          <Typography>No drugs</Typography>
                        )}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <EditDelBtn
                          noIcon
                          onHandleClick={() => {
                            const prescription = {
                              drugs,
                              createdAt: updatedAt,
                              doctorData,
                            };
                            setSinglePrescription(prescription);
                            setModalOpen(true);
                          }}
                          text="View details"
                          type="edit"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </EnhancedTable>
            ) : (
              <EmptyTable
                headCells={patientPrescriptionHeadCells}
                paginationLabel="Prescriptions per page"
              />
            )}
          </Grid>
        )}
      </TableLayout>
      <MainModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        headerText="Prescription details"
        width={{ md: "70vw", sm: "90vw", lg: "70vw", xs: "90vw" }}
      >
        <Grid item container width="100%" direction="row">
          <Grid item padding={{ md: "2rem 0", sm: "1rem 0", xs: "1rem 0" }}>
            <Grid item>
              <Grid item container alignItems="center" gap={1}>
                <Typography variant="body1" className={classes.title}>
                  Doctor:
                </Typography>
                {singlePrescription?.doctorData &&
                Object.keys(singlePrescription?.doctorData).length > 0 ? (
                  <Typography variant="h5">{`${singlePrescription?.doctorData?.firstName} ${singlePrescription?.doctorData?.lastName}`}</Typography>
                ) : (
                  <Typography variant="h5">No Doctor</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid item container alignItems="center" gap={1}>
                <Typography variant="body1" className={classes.title}>
                  Prescription Date:
                </Typography>
                <Typography variant="h5">
                  {dateMoment(singlePrescription?.updatedAt)}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid item>
                <Grid item container alignItems="center" gap={1}>
                  <Typography variant="body1" className={classes.title}>
                    Symptoms:
                  </Typography>
                  <Grid item>
                    <Grid container gap={1} flexWrap="wrap">
                      {symptoms ? (
                        symptoms.map((i) => {
                          return (
                            <Typography key={i.name} variant="h5">
                              {i?.name}
                            </Typography>
                          );
                        })
                      ) : (
                        <Typography variant="h5">No Value</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
          </Grid>
          <Divider color={theme.palette.common.lighterGrey} />
          <TableLayout>
            <Grid
              container
              item
              direction="column"
              overflow="hidden"
              maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
            >
              <EnhancedTable
                headCells={prescriptionTable}
                rows={[]}
                paginationLabel="Patients per page"
                hasCheckbox={false}
                hasPagination={false}
              >
                {(singlePrescription?.drugs || []).map((drug, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // return prescriptionDrugs.map((drug, idx) => {
                  return (
                    <TableRow key={index} hover tabIndex={-1}>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                        style={{
                          color: theme.palette.common.grey,
                          textAlign: "left",
                        }}
                      >
                        {drug && drug?.drugName}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                        style={{
                          color: theme.palette.common.grey,
                          textAlign: "left",
                        }}
                      >
                        {`${drug?.dosageUnit}`}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                        style={{
                          color: theme.palette.common.grey,
                          textAlign: "left",
                        }}
                      >
                        {duration(drug?.dosageFrequency?.timing)} daily <br />
                        For {daily(drug?.dosageFrequency?.duration)}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                        style={{
                          color: theme.palette.common.grey,
                          textAlign: "left",
                        }}
                      >
                        {drug && drug?.route}
                      </TableCell>
                    </TableRow>
                  );
                  // });
                })}
              </EnhancedTable>
            </Grid>
          </TableLayout>
          {/* <Grid
              item
              container
              padding={{ md: "2rem 0", sm: "1rem 0", xs: "1rem 0", lg: "2rem" }}
              alignItems="center"
              justifyContent="space-between"
              sx={{ flexWrap: "nowrap" }}
            >
              <Grid
                item
                container
                direction="column"
                gap={{ md: 2, sm: 2, xs: 0 }}
              >
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Doctors Note:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ lineHeight: 1.85 }}>
                    {doctorNote ? doctorNote : "No Value"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
        </Grid>
      </MainModal>
    </Grid>
  );
};

export default Prescriptions;
