import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Loader } from "components/Utilities";
import { useLazyQuery } from "@apollo/client";
import { CaseNoteContainer } from "./CaseNoteContainer";
import { Avatar, Grid, Typography } from "@mui/material";
import StatusPill from "components/Utilities/StatusPill";
import { useStyles } from "styles/singleConsultationPage";
import { getRefferal } from "components/graphQL/useQuery";
import { EditDelBtn } from "components/Buttons/EditDelBtn";
import { TestRefModal } from "components/modals/TestRefModal";
import { PrescriptionModal } from "components/modals/PrescriptionModal";

export const DoctorInfoCard = ({ consultationInfo }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [testRefOpen, setTestRefOpen] = useState(false);

  const [fetchTestRef, { data, loading }] = useLazyQuery(getRefferal);

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const {
    prescription,
    doctorData,
    doctorNote,
    doctorJoined,
    doctorEndCommunicationReason,
    diagnosis,
    referralId,
  } = consultationInfo;
  const hasDoctor = doctorData?.firstName && doctorData?.lastName;

  useEffect(() => {
    if (consultationInfo && referralId) {
      // fetch test ref
      fetchTestRef({ variables: { id: referralId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultationInfo]);

  const testRefArr = useMemo(() => {
    return data?.getReferral?.tests;
  }, [data]);

  return (
    <Grid
      item
      container
      direction="column"
      className={classes.parentGridWrapper}
    >
      <Typography
        variant="h3"
        sx={{
          textDecoration: "underline",
          marginBottom: "2rem",
        }}
      >
        Doctor information
      </Typography>

      <CaseNoteContainer
        item
        container
        flexWrap="wrap"
        display={{ lg: "grid", md: "grid", sm: "block" }}
        gridTemplateColumns={{ lg: "1fr 1fr 1fr" }}
        className={classes.item}
        alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
        rowGap={{ xs: "1.5rem", sm: "1.5rem", lg: "3rem" }}
        columnGap={{ xs: "1.5rem", sm: "1.5rem" }}
      >
        {/* DOCTOR NAME */}
        <Grid item>
          <Grid
            item
            container
            flexDirection="column"
            gap={1}
            className={classes.subItem}
          >
            <Grid item>
              <Typography variant="body3" className={classes.title}>
                Doctor name:
              </Typography>
            </Grid>
            <Grid item className={classes.info}>
              {doctorData && hasDoctor ? (
                <Grid
                  item
                  container
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/hcps/${doctorData?._id}`);
                  }}
                >
                  <Grid item marginRight={2}>
                    <Avatar
                      src={doctorData?.image}
                      alt={`Display photo of the ${doctorData?.firstName}`}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.info} ${classes.underline}`}
                    >{`${doctorData?.firstName} ${doctorData.lastName}`}</Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography className={classes.info}>No Doctor data</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/*  DOCTOR JOINED */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            <Grid item>
              <Typography className={classes.title}>Doctor joined:</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                {doctorJoined ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* DOCTOR END CALL REASON */}
        <Grid item>
          <Grid
            item
            container
            flexDirection="column"
            gap={1}
            className={classes.subItem}
          >
            <Grid item>
              <Typography variant="body3" className={classes.title}>
                Doctor end call reason:
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                {doctorEndCommunicationReason
                  ? doctorEndCommunicationReason
                  : "No reason"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* DOCTOR'S NOTE */}
        {doctorNote && (
          <Grid item>
            <Grid container className={classes.subItem} gap={1}>
              <Grid item>
                <Typography className={classes.title}>
                  Doctor&apos;s note:
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.info}>
                  {doctorNote ? doctorNote : "No doctor's note."}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/*  DIAGNOSIS */}
        {diagnosis && (
          <Grid item>
            <Grid container className={classes.subItem} gap={1}>
              <Grid item>
                <Typography className={classes.title}>Diagnosis:</Typography>
              </Grid>
              <Grid item container wrap="wrap" gap={1}>
                {diagnosis ? (
                  diagnosis.map((diagnosis, index) => {
                    return (
                      <StatusPill
                        key={index}
                        label={diagnosis?.ailment}
                        type="normal"
                      />
                    );
                  })
                ) : (
                  <Typography variant="body1">No symptoms</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {/*  PRESCRIPTIONS */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            {prescription && (prescription || []).length > 0 && (
              <Grid item>
                <EditDelBtn
                  type="edit"
                  text="View prescription(s)"
                  noIcon={true}
                  onClick={handleDialogOpen}
                />
              </Grid>
            )}
            <Grid item>
              {loading && <Loader />}
              {(testRefArr || []).length > 0 && (
                <EditDelBtn
                  type="edit"
                  text="View Test referral(s)"
                  noIcon={true}
                  onClick={() => setTestRefOpen(true)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </CaseNoteContainer>

      <TestRefModal
        isOpen={testRefOpen}
        handleDialogClose={() => setTestRefOpen(false)}
        consultationInfo={consultationInfo}
        testRefInfo={data?.getReferral || {}}
      />

      <PrescriptionModal
        isOpen={isOpen}
        handleDialogClose={handleDialogClose}
        consultationInfo={consultationInfo || {}}
      />
    </Grid>
  );
};

DoctorInfoCard.propTypes = {
  consultationInfo: PropTypes.object.isRequired,
};
