import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";

import { useTheme } from "@mui/material/styles";
import { isSelected } from "helpers/isSelected";
import SuccessImg from "assets/images/Success.png";
import MainModal from "components/modals/MainModal";
import { dateMoment } from "components/Utilities/Time";
import { removeEmptyStringValues } from "helpers/func";
import TableLayout from "components/layouts/TableLayout";
import StatusPill from "components/Utilities/StatusPill";
import { CustomButton, Loader } from "components/Utilities";
import { consultationFilterDefaultVal } from "helpers/mockData";
import { DatePickerComponent } from "components/validation/Date";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { EnhancedTable, NoData, EmptyTable } from "components/layouts";
import { useConsultationsStyles } from "styles/consultationsPageStyles";
import { allConsultationsHeader } from "components/Utilities/tableHeaders";
import ConsultationsFilters from "components/Forms/Filters/ConsultationsFilters";
import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  exportConsultations,
  getConsultations,
  sendReportToEmail,
  getProviders,
} from "components/graphQL/useQuery";

import {
  changeTableLimit,
  handlePageChange,
  filterData,
} from "helpers/filterHelperFunctions";

const Consultations = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { patientId, hcpId } = useParams();
  const classes = useConsultationsStyles();
  const [, /* errMsg */ setErrMsg] = useState(null);
  const [pageInfo, setPageInfo] = useState({});
  const [, /* downloadUrl */ setDownloadUrl] = useState(null);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [exportAllModal, setExportAllModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);

  const [consultations, setConsultations] = useState([]);
  const { selectedRows } = useSelector((state) => state.table);
  const disputed = `${location?.pathname}`.includes("disputes") ? true : false;
  const [loadingExportUrl /* setLoadingExportUrl */] = useState(false);
  const [providers, setProviders] = useState([]);
  const [email, setEmail] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [dataSuccessModal, setDataSuccessModal] = useState(false);
  const [provider, setProvider] = useState("");
  const [sendReport, setSendReport] = useState(false);
  const [
    generateDownloadUrl,
    { loading: downloadLoading, data: downloadData },
  ] = useLazyQuery(exportConsultations);

  const [startEndDate, setStartEndDate] = useState({
    startDate: null,
    endDate: null,
  });

  const { data: providerData, loading: providerLoading } = useQuery(
    getProviders,
    { variables: { page: -1 } }
  );

  const [consultationsFilterState, setConsultationsFilterState] = useState(
    consultationFilterDefaultVal
  );

  const [fetchConsultations, { loading, data, error, variables, refetch }] =
    useLazyQuery(getConsultations);

  const [
    generateConsultationsStat,
    { loading: emailLoading, data: sendEmailData /* error:sendEmailError */ },
  ] = useLazyQuery(sendReportToEmail);

  const onSubmitReportToEmail = async () => {
    const values = {
      email: email,
      startDate: startEndDate.startDate,
      endDate: startEndDate.endDate,
      providerId: provider,
    };
    const variables = removeEmptyStringValues(values);
    await generateConsultationsStat({
      variables: variables,
    });
  };

  const exportAllData = async () => {
    const values = {
      startDate: startEndDate.startDate,
      endDate: startEndDate.endDate,
      providerId: provider,
    };
    const variables = removeEmptyStringValues(values);

    await generateDownloadUrl({
      variables: variables,
    });

    setTriggerDownload(true);
  };

  useEffect(() => {
    if (
      triggerDownload &&
      downloadData &&
      downloadData.exportConsultations?.fileUrl
    ) {
      setTimeout(() => {
        window.location.href = downloadData.exportConsultations.fileUrl;
        setExportAllModal(false);
        setDataSuccessModal(true);
        setTriggerDownload(false);
      }, 500);
    }
  }, [downloadData, triggerDownload]);

  useEffect(() => {
    if (sendReport) {
      if (sendEmailData) {
        setEmailModal(false);
        setSuccessModal(true);
        setSendReport(false);
      }
    }
  }, [sendEmailData, sendReport]);

  const queryParams = useMemo(
    () => ({
      additionalVar: {
        orderBy: "-createdAt",
        first: pageInfo?.limit || 10,
        id: patientId,
        disputed,
      },
      varToDelete: variables,
      fetchConsultations,
      refetch,
    }),
    [
      disputed,
      fetchConsultations,
      pageInfo?.limit,
      patientId,
      refetch,
      variables,
    ]
  );

  const buttonType = useMemo(
    () => ({
      background: theme.palette.common.black,
      hover: theme.palette.primary.main,
      active: theme.palette.primary.dark,
    }),
    [
      theme.palette.common.black,
      theme.palette.primary.dark,
      theme.palette.primary.main,
    ]
  );
  const exportBtn = useMemo(() => {
    return {
      background: theme.palette.common.blue,
      hover: "#2b4a94",
      active: theme.palette.primary.dark,
      disabled: "#868686",
    };
  }, [theme.palette.common.blue, theme.palette.primary.dark]);

  const filter = (name, value) => {
    onFilterChange({ [name]: value });
  };

  const onFilterChange = async (newFilterValue) => {
    try {
      const values = {
        ...newFilterValue,
      };

      const filterValues = removeEmptyStringValues(values);
      await filterData(filterValues);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    fetchConsultations({
      variables: {
        orderBy: "-createdAt",
        patientId,
        doctorId: hcpId,
        disputed,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disputed, patientId]);

  useEffect(() => {
    if (data) {
      setConsultations(data.getConsultations.data);
      setPageInfo(data.getConsultations.pageInfo);
    }
  }, [data, consultations]);

  useEffect(() => {
    const providers = (providerData?.getProviders?.provider || []).map(
      (provider) => {
        return { key: provider.name, value: provider._id };
      }
    );
    setProviders(providers);
  }, [providerData]);

  if (error) return <NoData error={error.message} />;

  return (
    <Grid container gap={2} flexWrap="nowrap" direction="column" height="100%">
      <Grid
        item
        container
        justifyContent="flex-end"
        sx={{ margin: "1rem 0rem" }}
      >
        <div>
          <CustomButton
            title="Export data"
            width="100%"
            type={buttonType}
            onClick={() => {
              setExportModal(true);
            }}
          />
        </div>
      </Grid>
      <TableLayout
        filters={
          <ConsultationsFilters
            filterState={consultationsFilterState}
            setFilterState={setConsultationsFilterState}
            queryParams={queryParams}
            disabled={loading}
          />
        }
      >
        {loading ? (
          <Loader />
        ) : consultations.length > 0 ? (
          <Grid item container direction="column" height="100%">
            <EnhancedTable
              headCells={allConsultationsHeader}
              rows={consultations}
              paginationLabel="Patients per page"
              hasCheckbox={false}
              sx={{ cursor: "pointer" }}
              changeLimit={async (e) => {
                const filterVar = removeEmptyStringValues(variables);
                await changeTableLimit(fetchConsultations, {
                  ...filterVar,
                  first: e,
                  orderBy: "-createdAt",
                });
              }}
              dataPageInfo={pageInfo}
              handlePagination={async (page) => {
                const filterVar = removeEmptyStringValues(variables);
                await handlePageChange(fetchConsultations, page, pageInfo, {
                  orderBy: "-createdAt",
                  ...filterVar,
                });
              }}
            >
              {consultations.map((row) => {
                const {
                  doctorData,
                  patientData,
                  _id,
                  createdAt,
                  symptoms,
                  type,
                  status,
                  contactMedium,
                } = row;

                const isItemSelected = isSelected(_id, selectedRows);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={_id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (disputed) {
                        history.push(`/disputes/${_id}`);
                      } else {
                        history.push(`/consultations/case-notes/${_id}`);
                      }
                    }}
                  >
                    <TableCell align="left" className={classes.tableCell}>
                      {dateMoment(createdAt)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ maxWidth: "25rem" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: "1.25rem" }}>
                          {doctorData.firstName
                            ? `${doctorData.firstName} ${doctorData.lastName}`
                            : "No Doctor"}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ maxWidth: "25rem" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: "1.25rem" }}>
                          {patientData
                            ? `${patientData?.firstName} ${patientData?.lastName}`
                            : "No Patient"}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Grid container gap={1}>
                        {symptoms
                          ? symptoms.map((symptom, index) => {
                              const isLast = index === row.symptoms.length - 1;
                              return (
                                <p key={symptom.name}>
                                  {isLast
                                    ? `${symptom.name}.`
                                    : `${symptom.name},`}
                                </p>
                              );
                            })
                          : "No Value"}
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                        width: "4rem",
                      }}
                    >
                      {contactMedium ? contactMedium : "No Value"}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                      }}
                    >
                      {type ? type : "No Value"}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <StatusPill
                        label={status ? status : "No status"}
                        type={
                          status === "cancelled"
                            ? "error"
                            : status === "completed"
                            ? "success"
                            : status === "ongoing"
                            ? "normal"
                            : "unknown"
                        }
                      />
                      {/* <Chip
                        variant="contained"
                        label={status ? status : "No Value"}
                        className={classes.infoBadge}
                        style={{
                          background:
                            status === "completed" || status === "ongoing"
                              ? theme.palette.common.lightGreen
                              : theme.palette.common.lightRed,
                          color:
                            status === "completed" || status === "ongoing"
                              ? theme.palette.common.green
                              : theme.palette.common.red
                        }}
                      /> */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </EnhancedTable>
            {/* Start here */}
            <MainModal
              isOpen={exportAllModal}
              headerText="Consultation summary"
              width={"526px"}
            >
              <Typography
                sx={{ color: "#5D626C", fontSize: "13px", fontWeight: 400 }}
              >
                This is a report for all consultation summary
              </Typography>
              <Grid
                container
                flexDirection="column"
                gap="25px"
                sx={{ marginTop: "2rem" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      color: "#000000",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Choose Provider
                  </Typography>
                  <Autocomplete
                    sx={{ ":focus": { outline: "none" } }}
                    id="providerId"
                    name="providerId"
                    loading={providerLoading}
                    className={classes.autoComplete}
                    defaultValue={{
                      key: "",
                      value: "",
                    }}
                    disabled={loading}
                    onInputChange={(x) => x}
                    onChange={(e, info) => {
                      const providerId = info?.value;
                      setProvider(providerId);
                      filter("providerId", providerId);
                    }}
                    options={providers}
                    getOptionLabel={(option) => option.key}
                    renderOption={(props, option) => (
                      <li {...props}>{option?.key}</li>
                    )}
                    style={{ width: "100%", height: "48px" }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          ":focus": { outline: "none" },
                          "::placeholder": { color: "#CED0D3" },
                        }}
                        {...params}
                        placeholder="Select a Provider"
                      />
                    )}
                  />
                </Grid>
                <Typography
                  sx={{
                    marginBottom: "8px",
                    color: "#000000",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Date
                </Typography>
                <Grid
                  container
                  gap="16px"
                  justifyContent="space-between"
                  sx={{ marginTop: "-28px" }}
                  flexWrap="nowrap"
                >
                  <Grid sx={{ width: "250px" }}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      From:
                    </Typography>
                    <DatePickerComponent
                      // value={startEndDate?.startDate}
                      onHandleChange={(value) => {
                        setDownloadUrl(null);
                        setErrMsg(null);
                        setStartEndDate((prev) => ({
                          ...prev,
                          startDate: value,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid sx={{ width: "250px" }}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      To:
                    </Typography>
                    <DatePickerComponent
                      onHandleChange={(value) => {
                        setDownloadUrl(null);
                        setErrMsg(null);
                        setStartEndDate((prev) => ({
                          ...prev,
                          endDate: value,
                        }));
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  sx={{ margin: "1rem 0rem" }}
                >
                  <div>
                    <CustomButton
                      isSubmitting={downloadLoading}
                      title="Export Report"
                      width="142px"
                      type={exportBtn}
                      onClick={exportAllData}
                    />
                  </div>
                </Grid>
              </Grid>
            </MainModal>

            <MainModal
              isOpen={emailModal}
              headerText="Consultation summary"
              setIsOpen={() => {
                if (loadingExportUrl) return;
                setEmailModal(false);
              }}
              width={"526px"}
            >
              <Typography
                sx={{ color: "#5D626C", fontSize: "13px", fontWeight: 400 }}
              >
                This is a report for all consultation summary
              </Typography>
              <Grid
                container
                flexDirection="column"
                gap="22px"
                sx={{ marginTop: "2rem" }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      color: "#000000",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Choose Provider
                  </Typography>
                  <Autocomplete
                    sx={{ ":focus": { outline: "none" } }}
                    id="providerId"
                    name="providerId"
                    loading={providerLoading}
                    className={classes.autoComplete}
                    defaultValue={{
                      key: "",
                      value: "",
                    }}
                    disabled={loading}
                    onInputChange={(x) => x}
                    onChange={(e, info) => {
                      const providerId = info?.value;
                      setProvider(providerId);
                      filter("providerId", providerId);
                    }}
                    options={providers}
                    getOptionLabel={(option) => option.key}
                    renderOption={(props, option) => (
                      <li {...props}>{option?.key}</li>
                    )}
                    style={{ width: "100%", height: "48px" }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          ":focus": { outline: "none" },
                          "::placeholder": { color: "#CED0D3" },
                        }}
                        {...params}
                        placeholder="Select a Provider"
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      marginBottom: "8px",
                      color: "#000000",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Email address
                  </Typography>
                  <TextField
                    sx={{ ":focus": { outline: "none" }, width: "100%" }}
                    placeholder="xxxxx@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Typography
                  sx={{
                    marginBottom: "8px",
                    color: "#000000",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Date
                </Typography>
                <Grid
                  container
                  gap="16px"
                  justifyContent="space-between"
                  sx={{ marginTop: "-28px" }}
                  flexWrap="nowrap"
                >
                  <Grid sx={{ width: "250px" }}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      From:
                    </Typography>
                    <DatePickerComponent
                      // value={startEndDate?.startDate}
                      onHandleChange={(value) => {
                        setDownloadUrl(null);
                        setErrMsg(null);
                        setStartEndDate((prev) => ({
                          ...prev,
                          startDate: value,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid sx={{ width: "250px" }}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                      To:
                    </Typography>
                    <DatePickerComponent
                      onHandleChange={(value) => {
                        setDownloadUrl(null);
                        setErrMsg(null);
                        setStartEndDate((prev) => ({
                          ...prev,
                          endDate: value,
                        }));
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  sx={{ margin: "1rem 0rem" }}
                >
                  <div>
                    <CustomButton
                      isSubmitting={emailLoading}
                      title="Send Report"
                      width="142px"
                      type={exportBtn}
                      onClick={() => {
                        onSubmitReportToEmail();
                        setSendReport(true);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </MainModal>

            <MainModal
              isOpen={successModal}
              close={true}
              width={"526px"}
              padding={"48px"}
            >
              <Grid
                container
                flexDirection="column"
                gap="64px"
                width={"100%"}
                justifyContent="center"
                alignItems="center"
                height={"300px"}
              >
                <Grid gap="34px" flexDirection="column" justifyContent="center">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={SuccessImg}
                      alt="success_img"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <Typography
                    sx={{
                      color: "#000000",
                      width: "100%",
                      fontSize: "24px",
                      fontWeight: 500,
                    }}
                  >
                    Report sent successful
                  </Typography>
                </Grid>
                <Typography
                  onClick={() => setSuccessModal(false)}
                  sx={{
                    cursor: "pointer",
                    color: "#3E5EA9",
                    width: "100%",
                    fontSize: "13px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Back to Consultation
                </Typography>
              </Grid>
            </MainModal>

            <MainModal
              isOpen={dataSuccessModal}
              close={true}
              width={"526px"}
              padding={"48px"}
            >
              <Grid
                container
                flexDirection="column"
                gap="64px"
                width={"100%"}
                justifyContent="center"
                alignItems="center"
                height={"300px"}
              >
                <Grid gap="34px" flexDirection="column" justifyContent="center">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={SuccessImg}
                      alt="success_img"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <Typography
                    sx={{
                      color: "#000000",
                      width: "100%",
                      fontSize: "24px",
                      fontWeight: 500,
                    }}
                  >
                    Data sent successful
                  </Typography>
                </Grid>
                <Typography
                  onClick={() => setDataSuccessModal(false)}
                  sx={{
                    cursor: "pointer",
                    color: "#3E5EA9",
                    width: "100%",
                    fontSize: "13px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Back to Consultation
                </Typography>
              </Grid>
            </MainModal>

            <MainModal
              isOpen={exportModal}
              setIsOpen={() => {
                if (loadingExportUrl) return;
                setExportModal(false);
              }}
              headerText="Select a suitable option"
              width={"526px"}
            >
              <Grid
                container
                flexDirection="column"
                gap="16px"
                sx={{ marginTop: "2rem" }}
              >
                <Grid
                  item
                  borderRadius="8px"
                  border="1px solid rgba(128, 128, 128, 0.25)"
                  padding="8px 16px"
                  display="flex"
                  gap="2px"
                  sx={{
                    cursor: "pointer",
                    ":hover": { background: "#F1F5FC" },
                  }}
                  flexDirection="column"
                  onClick={() => {
                    setExportModal(false);
                    setExportAllModal(!exportAllModal);
                  }}
                >
                  <Typography
                    sx={{
                      ":hover": { color: "#3E5EA9" },
                      color: "#000000",
                      width: "100%",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    Export all data
                  </Typography>
                  <Typography
                    sx={{ color: "#5D626C", fontSize: "13px", fontWeight: 400 }}
                  >
                    This is to export all data{" "}
                  </Typography>
                </Grid>
                <Grid
                  onClick={() => {
                    setExportModal(false);
                    setEmailModal(!emailModal);
                  }}
                  item
                  borderRadius="8px"
                  border="1px solid rgba(128, 128, 128, 0.25)"
                  padding="8px 16px"
                  display="flex"
                  gap="2px"
                  sx={{
                    cursor: "pointer",
                    ":hover": { background: "#F1F5FC" },
                  }}
                  flexDirection="column"
                >
                  <Typography
                    sx={{
                      ":hover": { color: "#3E5EA9" },
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    Send report to email
                  </Typography>
                  <Typography
                    sx={{ color: "#5D626C", fontSize: "13px", fontWeight: 400 }}
                  >
                    This is to send all consultation summary to email{" "}
                  </Typography>
                </Grid>
              </Grid>
            </MainModal>
          </Grid>
        ) : (
          <EmptyTable
            headCells={allConsultationsHeader}
            paginationLabel="Patients per page"
          />
        )}
      </TableLayout>
    </Grid>
  );
};

export default Consultations;
